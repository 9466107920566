<template>

  <el-container class="container">
    <el-header class="header">
      <span class="left el-icon-arrow-left" @click="returnOn"></span>
      入库管理
    </el-header>
    <el-container style="      height: calc(90vh - 110px);">
      <el-container class="container1 left-container">
        <el-main class="main left-main">
          <div class="main-title">
            基本信息
          </div>
          <div class="body">
            <!-- <div class="cell" @click="stockDialogShow=true">
              <div class="left">入库仓库</div>
              <div class="right">{{stockName?stockName:'选择仓库名称'}}<span class="el-icon-arrow-right"></span></div>
            </div> -->
            <!-- <div class="cell">
              <div class="left">入库类型</div>
              <div class="right">
                <div :class="stockType===0?'item item1':'item'" @click="stockType=0">收货</div>
                <div :class="stockType===1?'item item1':'item'" @click="stockType=1">盘盈</div>
                <div :class="stockType===2?'item item1':'item'" @click="stockType=2">其他</div>
              </div>
            </div> -->
            <div class="cell">
              <div class="left">单据日期</div>
              <div class="right">
                <el-date-picker
                  v-model="timePicker"
                  type="date"
                  format="yyyy-MM-dd"
                  value-format="yyyy-MM-dd"
                  placeholder="选择日期">
                </el-date-picker>
              </div>
              <!-- {{timePicker}} -->
            </div>
            <div class="mark">
              <div class="mark-title">
                备注说明
              </div>
              <div class="mark-ipt">
                <el-input type="textarea" v-model="markInput" :rows="3" resize="none" placeholder="输入备注说明"
                  maxlength="300" show-word-limit>
                </el-input>
              </div>
            </div>
          </div>
        </el-main>
      </el-container>
      <el-container class="container1 right-container">
        <el-header>
          <div class="main-title">
            商品信息
            <div class="title-right">
              <div class="btn btn1" @click="clearGoods">清空已选</div>
              <div class="btn btn2" @click="goodsDrawerShow=true">添加商品</div>
            </div>
          </div>
        </el-header>
        <el-main class="main right-main">

          <div class="body">
            <div class="card" v-for="(item,index) in shopList" :key="index">
              <div class="card-title">
                <div class="name-close">
                  <div class="name">{{  item.name}}</div>
                  <!-- <div class="close el-icon-circle-close"></div> -->
                </div>
                <div class="size">{{ item.shopspecs_id }}</div>
              </div>
              <div class="card-item">
                <!-- <div class="top">
                  <div class="left">生产日期：<span class="text">2020年3月16日</span></div>
                  <div class="right">
                    <span class="el-icon-edit-outline" @click="batchDrawerShow=true"></span>
                    <span class="el-icon-circle-close"></span>
                  </div>
                </div> -->
                <!-- <div class="center">
                  批次号码：<span class="text">202102030002</span>
                </div> -->
                <div class="bottom">
                  <div class="left">入库数量：
                    <div class="stepper">
                      <el-input-number v-model="item.num" :precision="0" :min="1" :max="999" @change="changeVal">
                      </el-input-number>
                    </div>
                  </div>
                  <!-- <div class="right">
                    入库单价：<span class="text">¥99</span>
                  </div> -->
                </div>
              </div>
              <!-- <div class="card-item">
                <div class="top">
                  <div class="left">生产日期：<span class="text">2020年3月16日</span></div>
                  <div class="right">
                    <span class="el-icon-edit-outline" @click="batchDrawerShow=true"></span>
                    <span class="el-icon-circle-close"></span>
                  </div>
                </div>
                <div class="center">
                  批次号码：<span class="text">202102030002</span>
                </div>
                <div class="bottom">
                  <div class="left">入库数量：
                    <div class="stepper">
                      <el-input-number v-model="stepperNum" :precision="0" :min="1" :max="10">
                      </el-input-number>
                    </div>
                  </div>
                  <div class="right">
                    入库单价：<span class="text">¥99</span>
                  </div>
                </div>
              </div> -->
              <!-- <div class="add-btn el-icon-plus" @click="batchDrawerShow=true">
                添加批次
              </div> -->
            </div>
            <empty title="暂无商品信息" v-if="shopList.length == 0"></empty>
          </div>
        </el-main>
      </el-container>
    </el-container>
    <el-footer>
      <el-button type="primary" round @click="addStock">
        确认入库
      </el-button>
    </el-footer>

    <!-- 仓库 -->
    <el-dialog :visible.sync="stockDialogShow" :show-close="false" :before-close="handleClose">
      <template slot="title">
        <div class="dialog-title">
          <span></span><span class="center">选择仓库</span><span class="el-icon-close"
            @click="stockDialogShow=false"></span>
        </div>
      </template>
      <el-container style="height:420px;">
        <el-header class="dialog-header">
          <div class="search">
            <el-input placeholder="请输入仓库名称" clearable prefix-icon="el-icon-search" v-model="stockSearchVal">
            </el-input>
            <el-button type="primary">搜索</el-button>
          </div>
        </el-header>
        <el-main class="cell-list">
          <el-radio-group v-model="stockRadio">
            <el-radio :label="'仓库名称'+item" v-for="(item,i) in 20" :key="i">
              <div class="dialog-title">
                仓库名称{{item}}
              </div>
            </el-radio>
          </el-radio-group>
        </el-main>
        <el-footer>
          <div class="footer">
            <div class="btn" @click="stockConfirm">确定</div>
          </div>
        </el-footer>
      </el-container>
    </el-dialog>
    <!-- 商品 -->
    <el-drawer :visible.sync="goodsDrawerShow" :show-close="false" direction="rtl" :before-close="handleClose">
      <template slot="title">
        <div class="drawer-title">
          <span></span><span class="center">添加商品</span>
          <span class="el-icon-close" @click="goodsDrawerShow=false"></span>
        </div>
      </template>
      <el-container style="height:calc(100vh - 52px);">
        <el-header>
          <div class="search">
            <el-input placeholder="请输入商品名称搜索" clearable prefix-icon="el-icon-search" v-model="goodsSearchVal" @keyup.enter.native="searchChange">
            </el-input>
            <el-button type="primary" @click="searchChange">搜索</el-button>
          </div>
        </el-header>
        <el-main class="cell-list">
          <el-checkbox-group v-model="goodsCheck">
            <div class="card" v-for="(item,index) in goodList" :key="index">
              <div class="card-title">
                <div class="name-close">
                  <div class="name">{{  item.name}}</div>
                  <div class="close">
                    <el-checkbox :label="item.id">
                    </el-checkbox>
                  </div>
                </div>
                <div class="size">{{ item.shopspecs_id }}</div>
              </div>
              <div class="card-item">
                <!-- <div class="top">
                  <div class="left">生产日期：<span class="text">2020年3月16日</span></div>
                  <div class="right">
                    <span class="el-icon-edit-outline" @click="batchDrawerShow=true"></span>
                    <span class="el-icon-circle-close"></span>
                  </div>
                </div>
                <div class="center">
                  批次号码：<span class="text">202102030002</span>
                </div> -->
                <div class="bottom">
                  <div class="left">入库数量：
                    <div class="stepper">
                      <el-input-number v-model="item.num" :precision="0"  :min="1" :max="999" @change="changeVal">
                      </el-input-number>
                    </div>
                  </div>
                  <!-- <div class="right">
                    入库单价：<span class="text">¥99</span>
                  </div> -->
                </div>
              </div>
              <!-- <div class="card-item">
                <div class="top">
                  <div class="left">生产日期：<span class="text">2020年3月16日</span></div>
                  <div class="right">
                    <span class="el-icon-edit-outline" @click="batchDrawerShow=true"></span>
                    <span class="el-icon-circle-close"></span>
                  </div>
                </div>
                <div class="center">
                  批次号码：<span class="text">202102030002</span>
                </div>
                <div class="bottom">
                  <div class="left">入库数量：
                    <div class="stepper">
                      <el-input-number v-model="stepperNum" :precision="0" :min="1" :max="10">
                      </el-input-number>
                    </div>
                  </div>
                  <div class="right">
                    入库单价：<span class="text">¥99</span>
                  </div>
                </div>
              </div> -->
              <!-- <div class="add-btn el-icon-plus" @click="batchDrawerShow=true">
                添加批次
              </div> -->
            </div>
            <empty title="暂无商品信息" v-if="goodList.length == 0"></empty>
          </el-checkbox-group>
        </el-main>
        <el-footer>
          <div class="footer">
            <div class="left">共选择<b>{{  goodsCheck.length}}</b>项</div>
            <div class="btn right" @click="goodsConfirm">加入入库</div>
          </div>
        </el-footer>
      </el-container>
    </el-drawer>
    <!-- 添加批次 -->
    <el-drawer class="batch-drawer" :visible.sync="batchDrawerShow" :show-close="false" direction="rtl"
      :before-close="handleClose">
      <template slot="title">
        <div class="drawer-title">
          <span></span><span class="center">添加批次</span>
          <span class="el-icon-close" @click="batchDrawerShow=false"></span>
        </div>
      </template>
      <el-container style="height:calc(100vh - 52px);">
        <el-header>
          <div class="goods-name">
            <div class="name">菲灵魔法定型发胶</div>
            <div class="size">500ml</div>
          </div>
        </el-header>
        <el-main class="cell-input">
          <el-form ref="batchForm" :model="batchForm" label-width="80px">
            <el-form-item label="生产日期">
              <el-date-picker type="date" :editable="false" :clearable="false" placeholder="选择生产日期"
                v-model="batchForm.date">
              </el-date-picker>
            </el-form-item>
            <el-form-item label="批次号码">
              <el-input v-model="batchForm.number" placeholder="输入批次号码"></el-input>
            </el-form-item>
            <el-form-item label="入库数量">
              <div class="stepper">
                <el-input-number v-model="batchForm.stepperNum" :precision="0" :min="1" :max="10">
                </el-input-number>
              </div>
            </el-form-item>
            <el-form-item label="入库单价">
              <el-input v-model="batchForm.price" placeholder="输入入库单价"></el-input>
            </el-form-item>
          </el-form>
          <div class="hint el-icon-warning-outline">
            批次号最多16位，支持数字或字母；
          </div>
        </el-main>
        <el-footer>
          <div class="footer">
            <div class="btn" @click="batchConfirm">保存</div>
          </div>
        </el-footer>
      </el-container>
    </el-drawer>
  </el-container>
</template>

<script>
  export default {
    data() {
      return {
        // new Date().getFullYear() + '-' + ((new Date().getMonth() + 1) > 9 ? new Date().getMonth() + 1 :
        //   '0' + (new Date().getMonth() + 1)) + '-' + (new Date().getDate() > 9 ? new Date().getDate() : '0' +
        //   new Date().getDate())
        timePicker: '',
        stockType: 0,
        markInput: '', // 留言
        stepperNum: '', // 入库数量
        stockChangeShow: false,
        stockDialogShow: false,
        stockSearchVal: '',
        stockName: '',
        stockRadio: '',
        // 添加商品
        goodsDrawerShow: false,
        goodsSearchVal: '',
        goodsName: '',
        goodsCheck: [],
        // 添加批次
        batchDrawerShow: false,
        batchForm: {
          date: '',
          number: '',
          stepperNum: 1,
          price: '',
        },
        //商品列表
        goodList:[],
        //添加到商品列表
        shopList:[]
      }
    },
    computed: {},
    watch: {},
    created(){
      this.getGoodList()
    },
    mounted() {},
    methods: {
      searchChange() {
        this.getGoodList()
      },
      //添加入库
      addStock(){
        this.$http.post('api/store/stock/putstock',{
          time:this.timePicker,
          remark:this.markInput,
          sid:this.shopList
        }).then(res =>{
          this.$message.success(res.data.msg)
        })
      },
      //将数据拿出
      goodsConfirm() {
        this.shopList = []
        for(let k in this.goodsCheck){
          for(let j in this.goodList){
            if(this.goodsCheck[k] == this.goodList[j].id){
              this.shopList.push(this.goodList[j])
            }
          }
        }
        this.goodsDrawerShow = false
      },
      //获取商品列表
      getGoodList(){
        this.$http.post('api/store/shop/shop',{
          keyword:this.goodsSearchVal
        }).then(res =>{
          this.goodList = res.data.data
          if(this.goodList.length != 0){
            for(let i in this.goodList){
              this.goodList[i]['num'] = Number(1)
            }
          }
          
        })
      },
      
    changeVal() {
      this.$forceUpdate();//解决点击计数器失效问题
    },
      // 返回上级
      returnOn() {
        this.$router.go(-1)
      },
      // 清空已选
      clearGoods() {
        this.$confirm('确定要清空已选商品?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.shopList = []
          this.goodsCheck = []
          this.$message({
            type: 'success',
            message: '清除成功!'
          });
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消清除'
          });
        });
      },
      stockConfirm() {
        this.stockName = this.stockRadio
        this.stockDialogShow = false

      },
      
      batchConfirm() {
        this.batchDrawerShow = false
      },
      handleClose() {
        this.stockDialogShow = false
        this.goodsDrawerShow = false
        this.batchDrawerShow = false
      }
    }
  };

</script>
<style lang="less" scoped>
  .container {
    height: calc(100vh);
    background-color: #f9f9f9;

    // 头部
    .el-header {
      position: relative;
      z-index: 1;
      background-color: #fff;
      font-weight: bold;
      font-size: 16px;
      color: #333;
      height: 50px !important;
      line-height: 50px;
      text-align: center;
      border-bottom: 1px solid #e9e9e9;

      .left {
        position: absolute;
        left: 20px;
        top: 50%;
        transform: translateY(-50%);
        color: #46a6ff;
        font-size: 24px;
      }
    }

    .container1 {
      background-color: #fff;
      box-shadow: 0 0 2px #ccc;

      color: #666;

      .main-title {
        font-weight: bold;
        font-size: 16px;
        line-height: 50px;
        border-bottom: 1px solid #f2f2f2;
        padding: 0 15px;
        display: flex;
        justify-content: space-between;

        .title-right {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          font-weight: normal;
          font-size: 12px;

          .btn {
            width: 70px;
            line-height: 30px;
            text-align: center;
            border: 1px solid #409eff;
            border-radius: 5px;
            margin-left: 10px;
          }

          .btn1 {
            color: #409eff;
          }

          .btn2 {
            background-color: #409eff;
            color: #fff;
          }
        }
      }

      .body {
        padding: 0 15px;
      }
    }

    .left-container {
      width: 50%;
      margin: 20px 10px 20px 20px;

      .body {
        .cell {
          display: flex;
          justify-content: space-between;
          align-items: center;
          line-height: 50px;
          border-bottom: 1px solid #f2f2f2;

          .right {
            display: flex;
            justify-content: flex-end;
            align-items: center;

            .item {
              border: 1px solid #f2f2f2;
              line-height: 30px;
              text-align: center;
              width: 60px;
              margin-left: 5px;
              border-radius: 30px;
              font-size: 12px;
            }

            .item1 {
              border-color: #409eff;
              color: #409eff;
              background-color: #f6faff;
            }
          }
        }

        .mark {
          margin-top: 20px;

          .mark-title {
            line-height: 40px;
          }
        }
      }
    }

    .right-container {
      width: 50%;
      margin: 20px 20px 20px 10px;

      /deep/ .el-header {
        border-bottom: 0;

        .main-title {
          border-bottom: 0;
        }
      }
    }

    .card {
      padding: 15px;
      background-color: #f9f9f9;
      border: 1px solid #f2f2f2;
      border-radius: 10px;
      padding-right: 20px;
      margin-bottom: 15px;

      .card-title {
        border-bottom: 1px solid #f2f2f2;

        .name-close {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 10px 0;

          .close {
            font-size: 18px;
            color: #999;
          }
        }

        .size {
          margin-bottom: 10px;
          color: #999;

        }
      }

      .card-item {
        border-bottom: 1px solid #f2f2f2;
        padding: 10px 0;
        color: #999;

        .text {
          color: #666;
        }

        .top {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 10px;

          .right {
            font-size: 18px;
            color: #999;

            span {
              margin-left: 10px;
            }
          }
        }

        .center {
          margin-bottom: 10px;
        }

        .bottom {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 10px;

          .left {
            display: flex;
            justify-content: flex-start;
            align-items: center;

          }
        }
      }

      .add-btn {
        display: flex;
        justify-content: center;
        margin-top: 15px;
        color: #409eff;

        &::before {
          margin-right: 5px;
        }
      }
    }

    .el-footer {
      width: 100%;
      background-color: #fff;
      border-top: 1px solid #f2f2f2;
      display: flex;
      justify-content: center;
      align-items: center;

      .el-button {
        width: 30%;
      }
    }

    /deep/ .el-dialog {
      min-width: 400px;

      .el-dialog__header {
        padding: 0;
        margin: 0;
      }

      .el-dialog__body {
        height: 410px;
      }

      .dialog-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 15px;

        .center {
          font-size: 16px;
          font-weight: bold;
        }

        .el-icon-close {
          font-size: 20px;
          color: #409eff;
        }
      }

      .dialog-header.el-header {
        padding: 0 10px;
        box-sizing: content-box;
        border-bottom: none;

        .search {
          display: flex;
          justify-content: space-around;
          align-items: center;

          .el-button--primary {
            height: 40px;
            margin-left: -6px;
            z-index: 1;
            font-size: 12px;
            border-radius: 0 5px 5px 0;

          }

          .el-button--primary:focus,
          .el-button--primary:hover,
          .el-button:active,
          .el-button:focus,
          .el-button:hover {
            color: #fff;
            border-color: #409eff;
            background-color: #409eff;
          }

          /deep/ .el-input__inner {
            height: 40px;
            border: 1px solid #dcdfe6;
            border-right: 0;
            border-radius: 5px 0 0 5px;

            &:focus,
            &:hover {
              border-color: #dcdfe6;
            }
          }
        }
      }

      .cell-list {
        width: 100%;
        padding: 0 10px 10px !important;



        .el-radio-group {
          width: 100%;
          position: relative;

          .el-radio__input.is-checked+.el-radio__label {
            color: #666;
          }

          .el-radio {
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: 1px solid #f2f2f2;
            margin-right: 0;

            &:last-child {
              border-bottom: none;
            }

            .dialog-title {
              display: flex;
              justify-content: flex-start;
              align-items: center;
              font-size: 12px;
              padding: 15px 0;
            }

            .el-radio__input {
              position: absolute;
              right: 10px;
              top: 50%;
              transform: translateY(-50%);
            }
          }
        }

      }

      .el-footer {
        .footer {
          display: flex;
          justify-content: space-around;
          align-items: center;
          width: 100%;


          .btn {
            width: 80%;
            text-align: center;
            border: 1px solid #409eff;
            border-radius: 50px;
            line-height: 40px;
            background-color: #409eff;
            color: #fff;
            font-weight: bold;
          }
        }
      }
    }

    /deep/ .el-drawer {
      min-width: 400px;

      .el-drawer__header {
        padding: 0;
        margin: 0;
      }

      .drawer-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 15px;
        border-bottom: 1px solid #f2f2f2;

        .center {
          font-size: 16px;
          font-weight: bold;
        }

        .el-icon-close {
          font-size: 20px;
          color: #409eff;
        }
      }

      .el-header {
        padding: 20px;
        box-sizing: content-box;

        .search {
          display: flex;
          justify-content: space-around;
          align-items: center;

          .el-button--primary {
            height: 40px;
            margin-left: -6px;
            z-index: 1;
            font-size: 12px;
            border-radius: 0 5px 5px 0;

          }

          .el-button--primary:focus,
          .el-button--primary:hover,
          .el-button:active,
          .el-button:focus,
          .el-button:hover {
            color: #fff;
            border-color: #409eff;
            background-color: #409eff;
          }

          /deep/ .el-input__inner {
            height: 40px;
            border: 1px solid #dcdfe6;
            border-right: 0;
            border-radius: 5px 0 0 5px;

            &:focus,
            &:hover {
              border-color: #dcdfe6;
            }
          }
        }
      }

      .cell-list {
        width: 100%;
        padding: 10px 20px 20px !important;

        .el-checkbox-group {
          width: 100%;
          position: relative;
          font-size: 12px;

          .el-checkbox__input.is-checked+.el-checkbox__label {
            color: #666;
          }

          .el-checkbox {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-right: 0;
            background-color: #f9f9f9;
            border: 1px solid #f2f2f2;
            border-radius: 10px;
            margin-bottom: 15px;

            .el-checkbox__input {
              position: absolute;
              right: 10px;
              top: 50%;
            }
          }

          .card {
            .card-title {
              position: relative;

              .name-close {
                padding-top: 0;

                .name {
                  font-size: 14px;
                }
              }
            }
          }
        }

      }

      .el-footer {
        .footer {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;


          .btn {
            width: 40%;
            text-align: center;
            border: 1px solid #409eff;
            border-radius: 50px;
            line-height: 40px;
          }

          .left {
            color: #666;

            b {
              color: #f56c6c;
            }
          }

          .right {
            background-color: #409eff;
            color: #fff;
            font-weight: bold;
          }
        }
      }
    }

    .batch-drawer {
      /deep/.el-drawer__body {
        background-color: #f9f9f9;
      }

      .el-header {
        border-bottom: none;
        background-color: #f9f9f9;

        .goods-name {
          text-align: left;
          color: #666;
          line-height: 24px;
          font-weight: normal;

          .name {
            font-size: 14px;
          }

          .size {
            font-size: 12px;
            color: #999;
          }
        }
      }

      .cell-input {
        /deep/ .el-form {
          background-color: #fff;

          .el-form-item {
            display: flex;
            align-items: center;
            width: 100%;
            padding: 0 15px;
            box-sizing: border-box;


            .el-form-item__label {
              width: 30%;
            }

            .el-form-item__content {
              width: 70%;

              .el-input {
                width: 100%;

                .el-input__inner {
                  border: none;
                  text-align: right;
                  padding: 0;
                }

                .el-input__prefix {
                  display: none;
                }
              }
            }

            .stepper {
              display: flex;
              justify-content: flex-end;

              .el-input-number {
                .el-input__inner {
                  border: none;
                  text-align: center;
                }
              }
            }
          }
        }

        .hint {
          color: #ff9900;
          font-size: 12px;
          padding-left: 20px;
        }
      }

      .el-footer {
        .footer {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;

          .btn {
            width: 80%;
            border: 1px solid #409eff;
            border-radius: 50px;
            line-height: 40px;
            background-color: #409eff;
            color: #fff;
            font-weight: bold;
          }
        }
      }
    }
  }

  /deep/ .el-checkbox__label {
    display: none;
  }

  .stepper {

    /deep/ .el-input-number {
      width: 90px;
      height: 20px;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .el-input-number__decrease,
      .el-input-number__increase {
        width: 20px;
        height: 20px;
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 100%;
        border: 1px solid #ccc;
        color: #ccc;
        font-weight: bold;
      }

      .el-input {
        width: 100px;
        height: 34px;
        background-color: transparent;

        .el-input__inner {
          height: 20px;
          line-height: 20px;
          padding: 0 15px;
          border-color: #73a0fa;
          background-color: transparent;
          color: #999;
          font-weight: bold;
          border: none;
        }
      }
    }
  }

  .el-main {
    padding: 0 !important;
  }

</style>
